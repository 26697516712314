.RightSide {
  flex-basis: 60%;
  padding: 20px 0;
  margin: 0 auto;
  font-size: 2.2rem;
  font-weight: 500;
  @media (max-width: 1110px) {
    flex-basis: 95%;
  }
}
