@import '/src/style/variables.scss';

.widget {
  width: 100%;
  height: 620px;
  border-radius: 25px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  transition: all cubic-bezier(0.34, 0.85, 0.35, 0.86) 0.45s;
  display: flex;
  justify-content: space-between;
  position: relative;
  @media (max-width: 1110px) {
    height: 490px;
  }
  .last-update {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 1.3rem;
    user-select: none;
    @media (max-width: 1110px) {
      bottom: 3px;
      font-size: 1rem;
    }
  }
}
