.forecastDayList {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto 10px auto;
  @media (max-width: 1110px) {
    width: 440px;
    margin: 30px 0 10px auto;
  }
  .forecastDay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px;
    border-radius: 10px;
    padding: 15px 20px;
    transition: all cubic-bezier(0.34, 0.85, 0.35, 0.86) 0.34s;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.15);
    cursor: pointer;
    transform-style: preserve-3d;
    @media (max-width: 1110px) {
      margin: 5px;
    }
    &:hover {
      background-color: #0000007c;
      transform: scale(1.09);
      color: white;
    }
    &-Day {
      margin-bottom: 10px;
    }
  }
}
