$body: #1b1b1c;
$bg-header: rgb(70, 70, 70);
$bg-footer: rgb(70, 70, 70);
$link: #fff;

// Status
$status-bg: #808080;
$status-color: #fff;

// Night
$night-bg: #000;
$night-moon: #fff;
$night-moon-shadow: rgba(0, 0, 0, 0.2);
$night-star: #fff;
$night-star-shadow: rgba(255, 255, 255, 0.1);

// Rain
$rain-bg: #41ba9c;
$rain-cloud: #ccc;
$rain-shadow: #000;
$rain-drop: blue;

//Snow
$snow-bg: #ccd1d9;
$snow-cloud: #fff;
$snow-shadow: #000;
$snow-flake: #eee;
$snow-flake-alt: rgba(238, 238, 238, 0);

//Storm
$storm-bg: #656d78;
$storm-cloud: #393939;
$storm-shadow: #000;
$storm-lightning: yellow;
$storm-lightning-shadow: rgba(255, 258, 0, 0.4);

//Sunny
$sunny-bg: #4fc1e9;
$sunny-sun: #ffe400;
$sunny-sun-sun: rgba(255, 255, 0, 0.2);
$sunny-sun-outer: #ffe400;
$sunny-sun-outer-shadow: rgba(255, 255, 0, 0.1);
$sunny-sun-inner: #ffee44;
$sunny-sun-inner-shadow: rgba(255, 255, 0, 1);
$sunny-shadow: #000;

$yellow_20: rgba(255, 255, 0, 0.2);
$yellow_10: rgba(255, 255, 0, 0.1);
$white: #ffffff;
$white_10: rgba(255, 255, 255, 0.1);
$color_gallery_approx: #eeeeee;
$blue: blue;
$color_masala_approx: #3c3b3d;
$color_picton_blue_approx: #4fc1e9;
$color_mischka_approx: #ccd1d9;
$black: #000;
$color_nevada_approx: #656d78;
$color_keppel_approx: #41ba9c;
$gray: #808080;
$color_turbo_approx: #ffe400;
$color_gorse_approx: #ffee44;
$yellow_100: rgba(255, 255, 0, 1);
$black_20: rgba(0, 0, 0, 0.2);
$color_tuatara_approx: #393939;
$yellow: yellow;
$color_yellow_40_approx: rgba(255, 258, 0, 0.4);
$color_celeste_approx: #cccccc;
